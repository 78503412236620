<template>
    <!-- 员工管理 -->
    <div class="staff-admin">
        <div class="companySearch">
            <div>
                <el-button
                    @click="addFun"
                    class="titleButton"
                    type="primary"
                    icon="el-icon-plus"
                    >添加员工</el-button
                >
                <el-button @click="dialogVisible = true" class="titleButton"
                    >批量导入
                </el-button>
                <el-dialog
                    title="批量导入"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :before-close="handleClose"
                >
                    <div class="importCon">
                        <div>
                            为了保证导入成功，请按照下面模版的格式填写信息。
                        </div>
                        <div>
                            <a class="importBu" @click="downloadFun"
                                >模版.xlsx</a
                            >
                        </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-upload
                            ref="uploader"
                            action=""
                            :multiple="true"
                            :show-file-list="false"
                            :http-request="httpRequestUpload"
                        >
                            <el-button
                                @click="dialogVisible = false"
                                class="titleButton"
                                type="primary"
                            >
                                导入
                            </el-button>
                        </el-upload>
                    </span>
                </el-dialog>
            </div>
            <div class="titleSearch">
                <el-select @change="typeChange" v-model="region" placeholder="一级组织" clearable>
                    <el-option
                        v-for="(item, index) in regions"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-select v-model="regionTwo" placeholder="二级组织" clearable>
                    <el-option
                        v-for="(item, index) in regionTwos"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-select
                    class="gateSty"
                    v-model="examine"
                    placeholder="状态"
                    clearable
                >
                    <el-option
                        v-for="(item, index) in examines"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-input
                    @keyup.enter.native="searchClick"
                    clearable
                    v-model="input"
                    placeholder="请输入姓名/电话/身份证号码"
                ></el-input>
                <el-button
                    @click="searchClick"
                    class="titleButton"
                    type="primary"
                    icon="el-icon-search"
                    >搜索</el-button
                >
                <div class="contentTopAccount">
                    <el-button
                        class="titleButton"
                        icon="el-icon-download"
                        :loading="exportLoad"
                        >导出
                        <a title="data"></a>
                    </el-button>
                    <!-- 选项 -->
                    <div class="myDataSty">
                        <div
                            @click="myDataClick(index)"
                            class="myDataText"
                            v-for="(myData, index) in myDatas"
                            :key="index"
                        >
                            {{ myData }}
                        </div>
                    </div>
                </div>
                <el-button @click="codeClick" class="titleButton"
                    >采集二维码
                </el-button>
            </div>
        </div>
        <div class="companyTableC">
            <div class="companyTable">
                <el-table
                    v-loading="loading"
                    :data="pagingData.elements"
                    style="width: 100%"
                    height="100%"
                >
                    <el-table-column
                        prop="cardNo"
                        label="身份证号码"
                        min-width="130"
                    >
                    </el-table-column>
                    <el-table-column prop="name" label="姓名" min-width="80">
                    </el-table-column>
                    <el-table-column
                        prop="phoneNo"
                        label="手机号码"
                        min-width="110"
                    >
                    </el-table-column>
                    <el-table-column
                        label="所属组织"
                        min-width="160"
                    >
                        <template slot-scope="scope">
                            <div>{{ scope.row.deptName }}{{ scope.row.deptSonName?' - ':'' }}
                                {{ scope.row.deptSonName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createdAt"
                        label="添加时间"
                        min-width="160"
                    >
                    </el-table-column>
                    <el-table-column label="状态" min-width="100">
                        <template slot-scope="scope">
                            <div
                            class="issueSty" 
                            :class="{issueSty2:scope.row.examineC==='审核通过',examineSty3:scope.row.examineC==='审核不通过'}"
                            >
                                {{ scope.row.examineC }}
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="设备下发状态" width="110">
                        <template slot-scope="scope">
                            <div
                            class="issueSty"
                            :class="{issueSty2:scope.row.deviceIssueC==='下发成功',issueSty3:scope.row.deviceIssueC==='部分下发成功'}"
                            >
                            {{ scope.row.deviceIssueC }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="生效状态" min-width="80">
                        <template slot-scope="scope">
                            <div
                            class="examineSty3" 
                            :class="{issueSty2:scope.row.takeStateC==='已生效'}"
                            >
                                {{ scope.row.takeStateC }}
                            </div>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button
                                @click="editFun(scope.row, scope.$index)"
                                type="text"
                                >编辑</el-button
                            >
                            <el-dropdown
                                v-if="scope.row.isCheck !== 2"
                                placement="bottom"
                                @command="
                                    (command) => examineFun(command, scope.row)
                                "
                            >
                                <el-button type="text" class="el-dropdown-link">
                                    审核
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="-1"
                                        >审核不通过</el-dropdown-item
                                    >
                                    <el-dropdown-item :command="2"
                                        >审核通过</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-button
                                @click="deleteFun(scope.row, scope.$index)"
                                type="text"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <el-pagination
                :current-page="currentPage"
                :page-size="pagingData.pageSize"
                @current-change="handleCurrentChange"
                background
                layout="total, prev, pager, next"
                :total="pagingData.totalRecords"
            >
            </el-pagination>
        </div>
        <!-- 弹窗-添加/编辑员工 -->
        <addEditStaff
            v-if="addCompanyShow"
            @cancelEquipment-fun="cancelEquipmentFun"
            @determineEquipment-fun="determineEquipmentFun"
            :companyData="
                titlePop === '编辑员工' ? pagingData.elements[deleteIndex] : {}
            "
            :titlePop="titlePop"
        ></addEditStaff>
        <!-- 弹窗-删除 -->
        <deletePop
            v-if="deletePopShow"
            @deletePop-fun="deletePopFun"
            @determineDel-fun="determineDelFun"
            :companyName="companyName"
            :name="name"
            :catdTitle="catdTitle"
        ></deletePop>
        <!-- 审核 -->
        <el-dialog
            title="审核"
            :visible.sync="examineYN"
            width="30%"
            :before-close="handleClose"
        >
            <span
                >是否确认{{
                    commandName === -1 ? '审核不通过' : '审核通过'
                }}？</span
            >
            <span slot="footer" class="dialog-footer">
                <el-button @click="examineYN = false">取 消</el-button>
                <el-button type="primary" @click="examineFunT">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 二维码 -->
        <el-dialog title="采集二维码" :visible.sync="codeImg">
            <img :src="codeSrc" />
            <div>点击右键下载图片</div>
        </el-dialog>
    </div>
</template>
<script>
import addEditStaff from '../../components/addEditStaff'
import deletePop from '../../components/deletePop'

export default {
    name: 'staff-admin',
    components: {
        addEditStaff,
        deletePop,
    },
    data() {
        return {
            // 二维码 弹窗
            codeImg: false,
            // 二维码 路径
            codeSrc: '',
            // 审核 确认弹窗
            examineYN: false,
            // 审核 确认弹窗 内容
            commandName: '',
            // 审核 确认弹窗 员工ID
            rowId: '',
            // 导出 下拉
            myDatas: ['导出列表数据', '导出照片'],
            // 是否显示批量导入 对话框
            dialogVisible: false,
            loading: false,
            // 导出 loading
            exportLoad: false,
            // 权限判断
            isDis: null,
            titlePop: '编辑员工',
            // 是否显示-弹窗-编辑
            addCompanyShow: false,
            // 是否显示-弹窗-删除
            deletePopShow: false,
            // 一级组织
            region: null,
            regions: [],
            // 二级组织
            regionTwo: null,
            regionTwos:[],
            // 状态
            examine: null,
            examines: [
                { name: '待审核', id: 0 },
                { name: '审核不通过', id: -1 },
                { name: '审核通过', id: 2 },
                { name: '已注销', id: 3 },
                { name: '已失效', id: 4 },
            ],
            input: '',
            // 被选中员工名称
            companyName: '',
            name: '的员工信息',
            catdTitle: '删除员工',
            // 被选中员工下标
            deleteIndex: null,
            // 员工表格数据
            pagingData: {},
            // 分页-总条数
            totalNum: null,
            // 当前页数
            currentPage: 1,
            //列表-开始位置
            start: 1,
            // 上传的文件
            myFile: null,
        }
    },
    created() {
        // 不同权限 被访问到的页面
        let userInfo = sessionStorage.getItem('userInfo') + ''
        this.isDis = userInfo

        // 员工列表
        this.comtanyList()
        // 所属组织 下拉数据
        this.classifyGet()
    },
    methods: {
        // 点击 下载模版
        downloadFun() {
            let href = location.origin + '/员工导入模板.xlsx'
            const a = document.createElement('a')
            const event = new MouseEvent('click')
            // 下载图名字
            a.download = '员工导入模板.xlsx'
            // url
            a.href = href
            // 合成函数，执行下载
            a.dispatchEvent(event)
        },
        // 批量导入？ 关闭icon
        handleClose(done) {
            done()
        },
        // 批量导入 覆盖默认的上传行为，可以自定义上传的实现
        httpRequestUpload(file) {
            this.myFile = file.file
            let form = new FormData()
            form.append('userFile', this.myFile)
            this.$instance
                .post('/v1/staff/import-user', form)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.comtanyList()
                        this.$message({
                            message: '导入成功！',
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        // 点击 导出 内容
        myDataClick(index) {
            if (index === 0) {
                // 导出列表数据
                this.exportClick()
            } else if (index === 1) {
                // 下载员工照片
                this.photoClick()
            }
        },
        // 下载员工照片
        photoClick() {
            this.exportLoad = true
            this.$instance
                .get('/v1/staff/download_headpic', {
                    params: {},
                    responseType: 'arraybuffer',
                })
                .then((res) => {
                    // console.log('res: ', res)
                    let blob = new Blob([res.data], { type: 'application/zip' })
                    // IE
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, 'data')
                    } else {
                        this.imatateDownloadByA(
                            window.URL.createObjectURL(blob),
                            '员工照片'
                        )
                    }
                    this.exportLoad = false
                })
                .catch((error) => {
                    console.log('error: ', error)
                    this.exportLoad = false
                })
        },
        // 导出列表数据
        exportClick() {
            this.exportLoad = true
            this.$instance
                .get('/v1/staff/list/download', {
                    params: {
                        pageSize: 10000,
                        // 状态
                        isCheck: this.examine,
                        // 搜索关键字
                        searchKey: this.input,
                    },
                    responseType: 'arraybuffer',
                })
                .then((res) => {
                    const blob = new Blob([res.data], {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    })
                    // IE
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, 'data')
                    } else {
                        this.imatateDownloadByA(
                            window.URL.createObjectURL(blob),
                            '员工data'
                        )
                    }
                    this.exportLoad = false
                })
                .catch((error) => {
                    console.log('error: ', error)
                    this.exportLoad = false
                })
        },
        //  @param {String} href
        //  @param {String} filename
        imatateDownloadByA(href, filename) {
            const a = document.createElement('a')
            a.download = filename
            a.style.display = 'none'
            a.href = href
            // console.log('a.href: ', a.href)
            document.body.appendChild(a)
            a.click()
            a.remove()
            window.URL.revokeObjectURL(href)
        },
        // 一级组织 下拉数据
        classifyGet() {
            this.$instance
                .get('/v1/attendance/list', {
                    params: {
                        pageSize: 1000,
                    },
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.regions = res.data.data.elements
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 一级组织-选中值发生变化时触发
        typeChange(value) {
            this.regionTwos=[]
            this.regionTwo=null
            this.regions.map((val) => {
                if (val.id === value&&val.children.length) {
                    this.regionTwos=val.children
                }
            })
        },
        // 点击 采集二维码
        codeClick() {
            this.codeImg = true
            this.$instance
                .get('/v1/staff/reg_qr_pic', {
                    params: {},
                    responseType: 'arraybuffer',
                })
                .then((res) => {
                    //自定义名字 imgUrl 将请求回来的图片信息放到里面
                    this.codeSrc =
                        'data:image/jpeg;base64,' +
                        btoa(
                            new Uint8Array(res.data).reduce(
                                (data, byte) =>
                                    data + String.fromCharCode(byte),
                                ''
                            )
                        )
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 员工列表
        comtanyList() {
            this.loading = true
            this.$instance
                .get('/v1/staff/list', {
                    params: {
                        pageNum: this.currentPage - this.start,
                        pageSize: 10,
                        // 一级组织
                        deptId: this.region ? this.region : 0,
                        // 二级组织
                        deptSonId: this.regionTwo ? this.regionTwo : 0,
                        // 状态
                        isCheck: this.examine,
                        // 搜索关键字
                        searchKey: this.input,
                    },
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.loading = false
                        this.pagingData = res.data.data
                        this.pagingData.elements.map((val) => {
                            // 状态
                            this.examines.map(value=>{
                                if(value.id===val.isCheck){
                                    val.examineC = value.name
                                }
                            })
                            if(val.deviceIssue===0){
                                val.deviceIssueC='未下发'
                            }else if(val.deviceIssue===1){
                                val.deviceIssueC='下发成功'
                            }else if(val.deviceIssue===2){
                                val.deviceIssueC='部分下发成功'
                            }else if(val.deviceIssue===3){
                                val.deviceIssueC='不下发'
                            }
                            if(val.takeState===0){
                                val.takeStateC='未生效'
                            }else if(val.takeState===1){
                                val.takeStateC='已生效'
                            }else if(val.takeState===2){
                                val.takeStateC='失效'
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 点击 搜索
        searchClick() {
            // 当前页数
            this.currentPage = 1
            this.comtanyList()
        },
        // 点击分页-当前页
        handleCurrentChange(val) {
            this.currentPage = val
            // 搜索并拉取列表
            this.comtanyList()
        },
        // 点击 添加员工
        addFun() {
            this.addCompanyShow = true
            this.titlePop = '添加员工'
        },
        // 点击 编辑
        editFun(row, index) {
            this.addCompanyShow = true
            this.titlePop = '编辑员工'
            this.deleteIndex = index
        },
        // 弹窗-编辑-点击取消
        cancelEquipmentFun() {
            this.addCompanyShow = false
        },
        // 弹窗-编辑-点击确定
        determineEquipmentFun() {
            // 是否显示-弹窗-编辑
            this.addCompanyShow = false
            // 搜索并拉取列表
            this.comtanyList()
        },
        // 点击 审核
        examineFun(command, row) {
            this.examineYN = true
            this.commandName = command
            this.rowId = row.id
        },
        // 点击 审核确认框
        examineFunT() {
            this.$instance
                .post('/v1/staff/check', {
                    id: Number(this.rowId),
                    isCheck: this.commandName,
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.examineYN = false
                        // 搜索并拉取列表
                        this.comtanyList()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        // 点击 删除
        deleteFun(row, index) {
            this.deletePopShow = true
            this.deleteIndex = index
            this.companyName = row.name
        },
        // 弹窗-删除-点击取消
        deletePopFun() {
            this.deletePopShow = false
        },
        // 弹窗-删除-点击确定
        determineDelFun() {
            var value = this.pagingData.elements[this.deleteIndex].id
            this.$instance
                .post('/v1/staff/del?idStr=' + value, {})
                .then((res) => {
                    if (res.data.status === 200) {
                        this.comtanyList()
                        this.deletePopShow = false
                        this.$message({
                            message: '删除员工成功！',
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.staff-admin {
    width: calc(100% - 40px);
    margin: 20px;
    background-color: #fff;
    // 弹窗
    & ::v-deep .el-dialog {
        width: 400px;
        .el-dialog__body {
            text-align: center;
        }
    }
    // 搜索
    & ::v-deep .companySearch {
        width: 100%;
        height: 64px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        box-sizing: border-box;
        border-bottom: 1px #f0f0f0 solid;
        .el-button--primary {
            line-height: 32px;
            padding: 0 12px;
            border: unset;
        }
        .titleButton {
            line-height: 30px;
            padding: 0 12px;
        }
        .contentTopAccount {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            margin: 0 10px;
            .titleButton {
                width: 110px;
                line-height: 30px;
            }
            .myDataSty {
                display: none;
                width: 110px;
                position: absolute;
                top: 32px;
                right: 0;
                color: #333;
                background-color: #fff;
                border-radius: 4px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                text-align: center;
                z-index: 100;
                .myDataText {
                    line-height: 52px;
                    cursor: pointer;
                }
                .myDataText:hover {
                    color: #ff6600;
                }
            }
        }
        .contentTopAccount:hover {
            .myDataSty {
                display: block;
            }
        }

        // 批量导入？ 内容
        .importCon {
            display: flex;
            flex-direction: column;
            align-items: center;
            .importBu {
                color: #ff6600;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        // 批量导入？ 按钮
        .dialog-footer {
            display: flex;
            justify-content: center;
            .titleButton {
                width: 100px;
                margin-left: 10px;
            }
        }
        .titleSearch {
            display: flex;
            flex-wrap: wrap;
            .gateSty > .el-input {
                width: 120px !important;
            }
            .titleSear {
                position: relative;
                .el-select {
                    .el-input__inner {
                        padding-right: 46px;
                    }
                    .el-input__suffix {
                        margin-right: 18px;
                        .el-icon-arrow-up {
                            display: none;
                        }
                    }
                }
                .iconSearch {
                    width: 24px;
                    position: absolute;
                    top: 9px;
                    right: 12px;
                    color: rgb(182, 182, 182);
                    font-size: 14px;
                    background-color: #fff;
                    text-align: center;
                    z-index: 2;
                    cursor: pointer;
                }
            }
            .el-select > .el-input,
            .el-input {
                margin-right: 6px;
            }
            .el-select > .el-input {
                width: 120px;
            }
            .el-input {
                width: 230px;
                .el-input__inner {
                    height: 32px;
                    line-height: 32px;
                }
                .el-input__suffix {
                    i {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .companyTableC {
        width: calc(100% - 40px);
        height: calc(100% - 144px);
        margin: 20px;
        // 表格
        & ::v-deep .companyTable {
            height: 100%;
            border: 1px #f0f0f0 solid;
            overflow: auto;
            .buttonC {
                padding-right: 10px;
            }
            .el-table td,
            .el-table th {
                height: 49px;
                padding: 4px 0;
                .issueSty{
                    color: #999;
                }
                .examineSty3{
                    color: rgb(222, 49, 49);
                }
                .issueSty2{
                    color: rgb(53, 164, 53);
                }
                .issueSty3{
                    color: #ff6600;
                }
                .el-dropdown {
                    margin: 0 8px;
                }
            }
        }
        // 翻页
        .el-pagination {
            text-align: right;
            margin-top: 10px;
            padding: unset;
        }
    }
}
</style>
