<template>
    <!-- 添加/编辑员工 -->
    <div class="addEditStaff">
        <div class="mask"></div>
        <div class="equipmentCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ titlePop }}</div>
                <i class="el-icon-close" @click="cancelEquipmentFun"></i>
            </div>
            <el-form :model="myForm" ref="myForm" :rules="rules">
                <el-form-item label="身份证号码" prop="cardNo">
                    <el-input
                        v-model="myForm.cardNo"
                        placeholder="请输入身份证号码"
                        :disabled="titlePop === '编辑员工'"
                    ></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input
                        v-model="myForm.name"
                        placeholder="请输入姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phoneNo">
                    <el-input
                        v-model="myForm.phoneNo"
                        placeholder="请输入手机号码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所属一级组织" prop="typeNameC">
                    <el-select
                        @change="typeChange"
                        class="addressSty"
                        v-model="myForm.deptId"
                        placeholder="请选择所属一级组织"
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in enterpriseA"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属二级组织">
                    <el-select
                        @change="typeChange2"
                        class="addressSty"
                        v-model="myForm.deptSonId"
                        placeholder="请选择所属二级组织"
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in enterpriseATwo"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传照片">
                    <el-upload
                        class="avatar-uploader"
                        action=""
                        :show-file-list="false"
                        :http-request="beforeAvatarUpload"
                    >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        type="textarea"
                        v-model="myForm.remark"
                        placeholder="请输入备注"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="button">
                <el-button @click="cancelEquipmentFun">取 消</el-button>
                <el-button type="primary" @click="determineEquipmentFun"
                    >提 交</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'addEditStaff',
    props: {
        // 标题
        titlePop: {
            type: String,
            default: '添加员工',
        },
        // 被选择 信息
        companyData: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            // 照片
            imageUrl: '',
            // 照片 base64
            fileBase64: '',
            // 照片 base64--/9i
            fileB: '',
            // 0为批量导入 1为单个添加
            radio: '0',
            // 被选中 所属分类id
            typeCode: '',
            // 所属分类 选择
            enterpriseA: [],
            enterpriseATwo: [],
            // 表单
            myForm: {},
            rules: {
                cardNo: [
                    {
                        required: true,
                        message: '请输入证件号码',
                        trigger: 'blur',
                    },
                ],
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur',
                    },
                ],
                phoneNo: [
                    {
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur',
                    },
                ],
                typeNameC: [
                    {
                        required: true,
                        message: '请选择所属组织',
                        trigger: 'blur',
                    },
                ],
            },
        }
    },
    created() {
        // 所属一级组织
        this.classifyGet()
        const myForm = {
            ...this.companyData,
        }
        if (this.titlePop === '编辑员工') {
            this.imageUrl = 'data:image/jpeg;base64,' + myForm.headPic
        }
        this.myForm = myForm
    },
    methods: {
        // 照片格式
        beforeAvatarUpload(file) {
            this.imageUrl = URL.createObjectURL(file.file)

            const isJPG = file.file.type === 'image/jpeg'
            const isLt2M = file.file.size / 1024 / 1024 < 2

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!')
            } else if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!')
            } else {
                // 转base64
                this.getBase64(file.file).then((resBase64) => {
                    //直接拿到base64信息
                    this.fileBase64 = resBase64
                    this.fileB = resBase64.split(',')[1]
                })
                this.$message.success('文件上传成功')
            }
            return isJPG && isLt2M
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader()
                let fileResult = ''
                reader.readAsDataURL(file) //开始转
                reader.onload = function() {
                    fileResult = reader.result
                } //转 失败
                reader.onerror = function(error) {
                    reject(error)
                } //转 结束  咱就 resolve 出去
                reader.onloadend = function() {
                    resolve(fileResult)
                }
            })
        },
        // 所属一级组织-选中值发生变化时触发
        typeChange(value) {
            this.enterpriseATwo=[]
            this.myForm.deptSonId=null
            this.enterpriseA.map((val) => {
                if (val.id === value&&val.children.length) {
                    this.enterpriseATwo=val.children
                }
            })
        },
        typeChange2(){
            this.$forceUpdate()
        },
        // 所属一级组织 下拉数据
        classifyGet() {
            this.$instance
                .get('/v1/attendance/list', {
                    params: {
                        pageSize: 1000,
                    },
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.enterpriseA = res.data.data.elements
                        if(this.myForm.deptSonId){
                            this.enterpriseA.map(val=>{
                                if(val.id===this.myForm.deptId){
                                    this.enterpriseATwo=val.children
                                }
                            })
                        }else{
                            this.myForm.deptSonId=null
                        }
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 点击取消
        cancelEquipmentFun() {
            this.$emit('cancelEquipment-fun')
        },
        // 点击提交
        determineEquipmentFun() {
            if (
                !this.myForm.cardNo ||
                !this.myForm.name ||
                !this.myForm.phoneNo ||
                !this.myForm.deptId
            ) {
                this.$message({
                    message: '必填项不能为空！',
                    type: 'error',
                })
                return false
            }
            let deptId=null
            if(this.myForm.deptSonId){
                deptId=this.myForm.deptSonId
            }else{
                deptId=this.myForm.deptId
            }

            let value = {
                name: this.myForm.name,
                phoneNo: this.myForm.phoneNo,
                // 所属组织
                deptId,
                // 照片
                headPic: this.fileB,
                remark: this.myForm.remark,
            }
            let url = '/v1/staff/add'
            let url2 = '/v1/staff/edit'

            // this.radio 0为批量导入 1为单个添加
            if (this.titlePop === '添加员工') {
                this.gatePost(url, {
                    ...value,
                    // 身份证号码
                    cardNo: this.myForm.cardNo,
                })
            } else if (this.titlePop === '编辑员工') {
                this.gatePost(url2, { ...value, id: this.myForm.id })
            }
        },
        // 添加/编辑员工
        gatePost(url, value) {
            this.$instance
                .post(url, value)
                .then((res) => {
                    if (res.data.status === 200) {
                        this.$emit('determineEquipment-fun')
                        this.$message({
                            message: this.titlePop + '成功！',
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.addEditStaff {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }
    // 弹窗卡片
    & ::v-deep .equipmentCard {
        width: 520px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 6px;
        // 头部
        .cardHeader {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            padding: 0 40px;
            box-sizing: border-box;
            border-radius: 6px 6px 0 0;
            // 头部-icon
            .el-icon-close {
                font-size: 24px;
            }
        }
        .el-form {
            padding: 30px 40px 10px;
            border-top: 1px #f0f0f0 solid;
            border-bottom: 1px #f0f0f0 solid;
            .el-form-item {
                margin-bottom: 20px;
                .avatar-uploader .el-upload {
                    border: 1px dashed #d9d9d9;
                    border-radius: 6px;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                }
                .avatar-uploader .el-upload:hover {
                    border-color: #409eff;
                }
                .avatar-uploader-icon {
                    font-size: 28px;
                    color: #8c939d;
                    width: 100px;
                    height: 100px;
                    line-height: 100px !important;
                    text-align: center;
                }
                .avatar {
                    width: 100px;
                    height: 100px;
                    display: block;
                }
                .el-form-item__label {
                    width: 120px !important;
                    padding-right: 20px;
                    line-height: 32px;
                }
                .el-input__inner {
                    line-height: 32px !important;
                }
                .el-form-item__content {
                    margin-left: 120px !important;
                    line-height: 32px;
                    .equipSNSty {
                        margin-bottom: 14px;
                    }
                    .el-button {
                        width: 100px;
                    }
                    .el-select {
                        width: 100%;
                    }
                    input {
                        height: 32px;
                    }
                    i {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .button {
        padding: 20px 40px;
        display: flex;
        justify-content: flex-end;
        .el-button {
            line-height: 30px;
            padding: 0 20px;
        }
    }
}
</style>
